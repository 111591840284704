import React from 'react';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import Areas from 'containers/Areas/Areas';
import Header from 'components/Header/Header';
import DeliverySelection from 'components/DeliverySelection/DeliverySelection';
import { useStores } from 'hooks/useStores';
import Outlets from 'containers/Outlets/Outlets';
import { observer } from 'mobx-react';
import AreasContainer from 'containers/Areas/AreasContainer';
import Spinner from 'components/Spinner/Spinner';
import ChangeLocale from 'components/ChangeLocale/ChangeLocale';
import { withTranslation } from 'i18n';
import { toJS } from 'mobx';
import TestMode from 'components/TestMode/TestMode';
import agent from 'utils/agent';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { Fragment } from 'react';
import CustomNote from 'components/CustomNote/CustomNote';
import Map from 'containers/Areas/Map';
import { getThemeColor } from 'utils/_helper';
import AppLayout from 'containers/AppLayout/AppLayout';
import SEO from 'components/SEO/SEO';
import { Business } from 'utils/_agent-business';

const Restaurant = ({ i18n, name, data }) => {
  const router = useRouter();
  const { restaurant, orderType } = router.query;
  const { cartStore } = useStores();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // cartStore.setArea({});
    // console.log('DATA FROM SERVER:::', data);

    if (data && data.id) {
      cartStore.setRestaurant(data);
      cookie.set('et-slug', data.url, { expires: 1 });
      setLoading(false);
    }

    if (['pickup', 'delivery'].includes(orderType)) {
      switch (orderType) {
        case 'pickup':
          if (data && data.id) {
            if (data.has_pickup) cartStore.setOrderType('pickup');
            else if (data.has_delivery) cartStore.setOrderType('delivery');
          }
          break;

        case 'delivery':
          if (data && data.id) {
            if (data.has_delivery) cartStore.setOrderType('delivery');
            else if (data.has_pickup) cartStore.setOrderType('pickup');
          }
          break;
      }
    } else if (
      [
        'web',
        'f',
        'w',
        'i',
        'facebook',
        'whatsapp',
        'instagram',
        'sms'
      ].includes(orderType)
    ) {
      let ch = 'web';

      switch (orderType) {
        case 'f':
          ch = 'facebook';
          break;
        case 'facebook':
          ch = 'facebook';
          break;
        case 'i':
          ch = 'instagram';
          break;
        case 'instagram':
          ch = 'instagram';
          break;
        case 'w':
          ch = 'whatsapp';
          break;
        case 'whatsapp':
          ch = 'whatsapp';
          break;
        case 'sms':
          ch = 'sms';
        case 'qr':
          ch = 'qr';
        default:
          ch = 'web';
      }

      if (document.referrer && document.referrer.indexOf('facebook.com') >= 0) {
        cartStore.setChannel('facebook');
      } else if (
        document.referrer &&
        document.referrer.indexOf('instagram.com') >= 0
      ) {
        cartStore.setChannel('instagram');
      } else {
        cartStore.setChannel(ch);
      }
      // console.log('OUTLET PAGE:', toJS(currentOutlet));
      // api.defaults.headers.common['restaurant-id'] = currentRestaurant.id;
      // console.log("Outlet Page");

      if (cartStore.currentRestaurant.domain) router.push(`/`);
      else
        router.push(`/[restaurant]`, `/${restaurant}`, {
          // shallow: false
        });
    } else {
      router.push('/404');
    }

    // if (!cartStore.orderType) {
    //   // console.log('Setting Delivery');
    //   cartStore.setOrderType('delivery');
    // }

    // if (
    //   cartStore.currentRestaurant &&
    //   !cartStore.currentRestaurant.has_delivery
    // ) {
    //   // console.log('Setting Pickup');
    //   cartStore.setOrderType('pickup');
    // }
  }, [data]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <SEO data={data} />
      <Head>
        <meta name="application-name" content={`${data.name.en}`} />
        <meta name="apple-mobile-web-app-title" content={`${data.name.en}`} />
        <meta
          name="description"
          content={`Order direct from ${data.name && data.name.en
            } via Eatroot and enjoy best offers`}
        />
        {data.settings && data.settings.theme_style && (
          <meta name="msapplication-TileColor" content={getThemeColor(data)} />
        )}
      </Head>
      <AppLayout> 

        <TestMode />
        <div
          className="relative flex flex-col justify-center"
          style={{ minHeight: 100 }}
        >
          {data && data.media && data.media.banner && (
            <img
              src={data.media.banner}
              alt={
                data.name[i18n.language]
                  ? data.name[i18n.language]
                  : data.name.en
              }
              className='w-full'
            />
          )}
          <div className="mx-4">
            <h1 className="font-medium mt-4">
              {data.name[i18n.language]
                ? data.name[i18n.language]
                : data.name.en}
            </h1>
          </div>
          {data && data.custom_note && data.custom_note.en ? (
            <CustomNote custom_note={data.custom_note} />
          ) : null}
          {/* <Header name={cartStore.currentRestaurant.name[i18n.language]} /> */}
          <div
            className={`absolute top-0 ${i18n.language == 'ar' ? 'left-0' : 'right-0'
              } px-4 py-4`}
          >
            <ChangeLocale />
          </div>
        </div>
        <div className="mt-6 mx-4">
          <DeliverySelection />
        </div>
        {/* {orderType == 'pickup' ? <Outlets /> : <Map />} */}
        {orderType == 'pickup' ? <Outlets /> : <AreasContainer />}
      </AppLayout>
    </Fragment>
  );
};

// export async function getStaticPaths() {
//   return {
//     paths: [], //indicates that no page needs be created at build time
//     fallback: 'blocking' //indicates the type of fallback
// }
// }

export async function getServerSideProps({ params, res }) {
  const { restaurant } = params;
  // const res = await fetch(`https://...`)
  let data = {};
  let r = {};

  // console.log('ORDER TYPE::', restaurant);

  await Business.all(`/business/${restaurant}`)
    .then((response) => {
      // console.log('Restaurant Loaded', response);
      if (response.id) {
        data = response;
        r = {
          props: { data } // will be passed to the page component as props
        };
      } else {
        r = {
          notFound: true
        };
      }
    })
    .catch((err) => {
      // console.log('ERROR TYPE:::', err.response.status);
      //         // if (err.response) {
      if (
        err &&
        err.response &&
        err.response.status &&
        err.response.status == 404
      ) {
        // router.push('/404');
        res.statusCode = 404;
      }
      r = {
        notFound: true
      };
    });

  return r;
}

export default withTranslation('common')(Restaurant);
